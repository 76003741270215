<template>
  <div class="mb-4">
    <DsfrAlert
      title="Historique des déclarations soumises sur Teleicare"
      description="Les produits que vous avez déjà déclarés sur Téléicare ont été importés grâce à une correspondance basée sur les SIRET et les numéros de TVA intracommunautaire.
        L'étiquetage et l'entreprise mandataire s'il y en a ne sont pas encore disponibles dans cet historique.
        En attendant, vous pouvez accéder à l'historique complet directement sur Téléicare."
      type="warning"
    ></DsfrAlert>
  </div>
</template>
